import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { IProductTitleProps } from 'gatsby-theme-husky/src/components/ProductTitle/model';

import 'gatsby-theme-husky/src/components/ProductTitle/ProductTitle.scss';
import './ProductTitleOverride.scss';

const ProductTitle: FC<IProductTitleProps> = ({
  title,
  amountCondoms,
  classes,
  activeVariant,
  onChangeVariant,
}) => {
  const classesTitle = classNames('product-title-wrapper', {
    [`${classes}`]: classes,
  });

  const condomAmountArray = useMemo(() => amountCondoms?.split(','), [amountCondoms]);

  return (
    <div className={classesTitle}>
      <h1 className="product-title">{title}</h1>
      <div className="product-title__amount-condoms">
        {condomAmountArray?.map((el) => (
          <button
            key={el}
            type="button"
            className={classNames('product-title__amount-condoms__button', {
              'product-title__amount-condoms__button--active':
                activeVariant && activeVariant === el.trim(),
              'product-title__amount-condoms__button--single': condomAmountArray.length === 1,
            })}
            aria-label={el.trim()}
            onClick={onChangeVariant && onChangeVariant(el.trim())}
          >
            {el.trim()}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ProductTitle;
