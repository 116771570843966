import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { graphql, Link } from 'gatsby';
import { DangerouslySetInnerHtml, Layout } from 'gatsby-theme-husky/src/layout';
import ProductTitle from 'gatsby-theme-husky/src/components/ProductTitle';
import ProductFeatures from 'gatsby-theme-husky/src/components/ProductFeatures';
import ProductDescription from 'gatsby-theme-husky/src/components/ProductDescription';
import ProductAdvantages from 'gatsby-theme-husky/src/components/ProductAdvantages';
import Button from 'gatsby-theme-husky/src/common/Button';
import ProductCarousel from 'gatsby-theme-husky/src/components/ProductCarousel';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import Breadcrumbs from 'gatsby-theme-husky/src/components/Breadcrumbs';
import ReviewStars from 'gatsby-theme-husky/src/components/ReviewStars';
import ProductReview from 'gatsby-theme-husky/src/components/ProductReview';
import RelatedProducts from 'gatsby-theme-husky/src/components/RelatedProducts';
import BuyNowDropdown from 'gatsby-theme-husky/src/components/BuyNowDropdown';
import { IProductPageProps } from 'gatsby-theme-husky/src/templates/ProductPage/model';

import 'gatsby-theme-husky/src/templates/ProductPage/ProductPageMain.scss';
import 'gatsby-theme-husky/src/templates/ProductPage/ProductPageRTL.scss';

const ProductPage: FC<IProductPageProps> = ({ data: { umbracoProducts, relatedProducts } }) => {
  const {
    defaultCompositions,
    properties: {
      seoMetaTitle,
      seoCanonicalUrl,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      title,
      amountCondoms,
      productAdvantages,
      productDescriptionText,
      productFeatures,
      buttons,
      image,
      sku,
      turnBackPDPText,
      buyNowDisclaimer,
      buyNow,
    },
    url,
  } = umbracoProducts;
  const [productVariant, setProductVariant] = useState<string>('');
  const { isMobile } = useScreenRecognition();

  const urlToCategoryLink = useMemo(() => {
    const urlToCategory = url.split('/').filter((link) => link);
    urlToCategory.pop();

    return `/${urlToCategory.join('/')}`;
  }, [url]);

  useEffect(() => {
    if (amountCondoms) {
      setProductVariant(amountCondoms.split(',')[0]);
    }
  }, []);

  const { showReviewPanel } = defaultCompositions?.commonSettings || {};
  const isReviewPanelVisible = !!Number(showReviewPanel);

  const onChangeVariant = useCallback((variant: string) => () => setProductVariant(variant), []);

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoCanonicalUrl,
        seoExternalHreflangs,
      }}
    >
      {isMobile ? (
        <div className="product__breadcrumbs--mobile">
          <Link to={urlToCategoryLink}>
            <div className="icon-arrow-left" />
            <span>{turnBackPDPText}</span>
          </Link>
        </div>
      ) : (
        <Breadcrumbs url={url} className="product__breadcrumbs" />
      )}
      <div className="product-page">
        <div className="product-banner">
          {isMobile && (
            <ProductTitle
              title={title}
              amountCondoms={amountCondoms}
              activeVariant={productVariant}
              onChangeVariant={onChangeVariant}
              classes="productTitleOrder"
            />
          )}
          <ProductCarousel
            isMobile={isMobile}
            images={image}
            isFirstViewport
            classes="product-carousel"
          />
          <div className="product-right-pack">
            {!isMobile && (
              <>
                <ProductTitle
                  title={title}
                  amountCondoms={amountCondoms}
                  activeVariant={productVariant}
                  onChangeVariant={onChangeVariant}
                  classes="productTitleOrder"
                />
                {isReviewPanelVisible ? (
                  <ReviewStars className="product-reviews" sku={sku} />
                ) : null}
              </>
            )}
            <div className="product-buttons">
              {isMobile && isReviewPanelVisible ? (
                <ReviewStars sku={sku} className="product-reviews" />
              ) : null}
              {buttons?.map((btn, index) => (
                <Button
                  key={`${btn.properties.buttonText}${index + 90}`}
                  ariaLabel={btn.properties.buttonText}
                  link={btn.properties.buttonLink[0].url}
                  btnColorVariant="dark"
                  className="product-buttons-styles"
                >
                  {btn.properties.buttonText}
                </Button>
              ))}
            </div>
            {buyNow?.[0] ? <BuyNowDropdown buyNow={buyNow} activeVariant={productVariant} /> : null}
            {buyNowDisclaimer ? (
              <DangerouslySetInnerHtml html={buyNowDisclaimer} className="product-disclaimer" />
            ) : null}
          </div>
        </div>

        {productFeatures && productFeatures.length > 0 ? (
          <ProductFeatures productFeatures={productFeatures} />
        ) : null}
        {productDescriptionText && productDescriptionText.length > 0 ? (
          <ProductDescription productDescriptionText={productDescriptionText} />
        ) : null}
      </div>
      {productAdvantages && productAdvantages.length > 0 ? (
        <ProductAdvantages productAdvantages={productAdvantages} />
      ) : null}
      {isReviewPanelVisible ? <ProductReview sku={sku} /> : null}
      <div className="related-products__wrapper">
        <RelatedProducts
          relatedProducts={relatedProducts}
          relatedProductsCardCtaButton={umbracoProducts.properties.relatedProductsCardCtaButton}
          relatedProductsCtaButtonShow={umbracoProducts.properties.relatedProductsCtaButtonShow}
          relatedProductsTitle={umbracoProducts.properties.relatedProductsTitle}
          relatedProductsCardCTAButtonAriaLabel={
            umbracoProducts.properties.relatedProductsCardCTAButtonAriaLabel
          }
          relatedProductsCtaButton={umbracoProducts.properties.relatedProductsCtaButton}
        />
      </div>

      {isReviewPanelVisible ? (
        <div
          className="yotpo yotpo-main-widget"
          data-product-id={sku}
          data-name={title}
          data-url={url}
        />
      ) : null}
    </Layout>
  );
};
export const query = graphql`
  query ProductPageGR($url: String!, $links: [String]) {
    umbracoProducts(url: { eq: $url }) {
      url
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      properties {
        seoMetaDescription
        seoMetaKeywords
        seoMetaTitle
        seoCanonicalUrl
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        image {
          properties {
            imageAltLabel
          }
          mobileImage: localImage {
            childImageSharp {
              fluid(maxWidth: 200, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktopImage: localImage {
            childImageSharp {
              fluid(maxWidth: 420, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sku
        title
        amountCondoms
        turnBackPDPText
        productAdvantages {
          properties {
            productAdvantage
            productAdvantageComment
          }
        }
        buttons {
          properties {
            buttonText
            buttonLink {
              url
            }
          }
        }
        buyNow {
          properties {
            variant
            triggerButtonTitle
            triggerButtonAriaLabel
            dropdownTitle
            closeButtonTitle
            closeButtonAriaLabel
            shops {
              properties {
                ...FragmentIconButton
              }
            }
          }
        }
        productDescriptionText {
          properties {
            descriptionText
            descriptionTitle
          }
        }
        productFeatures {
          properties {
            productFeaturesText {
              value
            }
            productTextTitle
          }
        }
        relatedProductsCardCtaButton
        relatedProductsCtaButtonShow
        relatedProductsTitle
        relatedProductsCardCTAButtonAriaLabel
        relatedProductsCtaButton {
          url
          name
        }
        buyNowDisclaimer
      }
    }
    relatedProducts: allUmbracoProducts(filter: { url: { in: $links } }) {
      nodes {
        url
        properties {
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
      }
    }
  }
`;

export default ProductPage;
